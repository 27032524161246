* {
    font-family: "Roboto", sans-serif;
    letter-spacing: 1px;
    scroll-behavior: smooth;
}

.product_banner {
    background: url(../src/Assets/Product_banner.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 80vh;
}

.dem {
    background-color: blue;
}

.app__newsletter {
    padding: 2rem 4rem;
    border: 1px solid var(--color-golden);
    background: #E0F4EC;
}

.app__newsletter-heading {
    text-align: center;
}

.app__newsletter-input {
    flex-direction: column;
    margin-top: 3rem;
}

.app__newsletter-input input {
    width: 620px;
    margin-bottom: 1rem;
    border: 1px solid #374151;
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--color-white);

    margin-right: 2rem;
    padding: 0.75rem 1rem;
    background: var(--color-black);
}

@media screen and (min-width: 2000px) {
    .app__newsletter-input input {
        font-size: 2rem;
    }
}

@media screen and (max-width: 990px) {
    .app__newsletter-input {
        flex-direction: column;
        width: 100%;
    }

    .app__newsletter-input input {
        margin: 0 0 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app__newsletter {
        padding: 2rem 0;
        border: none;
    }
}

@media screen and (max-width: 300px) {
    .app__newsletter-heading h1 {
        font-size: 32px;
        line-height: 50px;
    }
}





.section__padding {
    padding: 4rem 6rem;
}

.flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__bg {
    /* background: url('./assets/bg.png'); */
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
}

.app__wrapper_img img {
    width: 80%;
}

.custom__button {
    background-color: var(--color-crimson);
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
}

.p__opensans {
    color: white;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
}

.headtext__cormorant {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 64px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
}

.spoon__img {
    width: 45px;
}

@media screen and (min-width: 2000px) {

    .custom__button {
        font-size: 37px;
        line-height: 67px;
    }

    .p__opensans {
        font-size: 30px;
        line-height: 50px;
    }

    .headtext__cormorant {
        font-size: 150px;
        line-height: 210px;
    }

    .spoon__img {
        width: 80px;
    }
}

@media screen and (max-width: 1150px) {

    .app__wrapper_img img {
        width: 100%;
    }
}

@media screen and (max-width: 850px) {
    .section__padding {
        padding: 4rem;
    }
}

@media screen and (max-width: 650px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .p__opensans {
        font-size: 14px;
    }
}

@media screen and (max-width: 450px) {
    .p__opensans {
        font-size: 12px;
    }

    .headtext__cormorant {
        font-size: 45px;
        line-height: 70px;
    }
}